import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { AppConfig } from '../../classes/app.config';
import { ArmazenamentoService } from '../armazenamento/armazenamento.service';
import { UsuarioLogadoService } from '../usuarioLogado/usuario-logado.service';
import { UsuarioLogado } from './../../classes/usuario-logado';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private urlLRApi = AppConfig.obterUrlLRApi()
  private usuarioLogado: UsuarioLogado
  private tempoRenovacaoToken = 10
  private nTentativa: number = 0;
  private Controller = 'Auth'

  constructor(
    private _httpS: HttpClient,
    private _armazenamento: ArmazenamentoService,
    private _usuarioLogadoS: UsuarioLogadoService
  ) {
    this.usuarioLogado = this._usuarioLogadoS.obterUsuarioLogado()

    this._usuarioLogadoS.usuarioEmitter.subscribe(
        () => {
            this.usuarioLogado = this._usuarioLogadoS.obterUsuarioLogado()
        }
    )
  }

  definirUsuarioLogado = (usuarioLogado, manterLogado = null) => {
    if (manterLogado) {
      localStorage.setItem('modalidadeLogin', manterLogado ? 'local' : 'session')
    }
    this._armazenamento.definirItem('LRLogU', JSON.stringify(usuarioLogado))
    this._usuarioLogadoS.carregarUsuarioLogado()
  }

  login = (username, password) => {
    let req = { username, password }

    return this._httpS.post(this.urlLRApi + `/${this.Controller}/login`, req)
        .pipe(map(res => {
          this.definirUsuarioLogado(res['data'])
          this.iniciarTimerRenovarToken()
          return res
        }
      )
    )
  }

  logout = () => {
    this.pararTimerRenovarToken()

    localStorage.removeItem('modalidadeLogin')
    localStorage.removeItem('LRLogU')
    sessionStorage.removeItem('modalidadeLogin')
    sessionStorage.removeItem('LRLogU')

    location.reload()
  }

  private obterTempoRestanteToken = () => {
    const expiracao = new Date(this.usuarioLogado.expiresIn)
    const dataAtual = new Date()
    let timeout = Math.abs(expiracao.getTime() - dataAtual.getTime()) / 1000

    return expiracao.getTime() > dataAtual.getTime()
        ? timeout
        : -timeout
  }

  renovarToken = () => {
    let req: any
    
    try {
        req = {
            username: this._usuarioLogadoS.obterUsuarioLogado().userToken.username,
            refreshToken: this._usuarioLogadoS.obterUsuarioLogado().refreshToken
        }
    } catch (err) { 

    } finally {
        if (req) {
            if (this.obterTempoRestanteToken() < this.tempoRenovacaoToken) {
                this._httpS.post(`${this.urlLRApi}/${this.Controller}/refresh`, req, { 
                    headers: new HttpHeaders().set('Authorization', `bearer ${this._usuarioLogadoS.obterUsuarioLogado().accessToken}`)
                }).subscribe(
                    res => {
                        this.definirUsuarioLogado(res['data'])
                        this.iniciarTimerRenovarToken()
                    }, err => {
                      if(this.nTentativa < 3){
                        this.nTentativa ++;
                        this.renovarToken();
                      }else{
                        this.nTentativa = 0;
                        this.logout()
                      }
                    }
                )
            } else {
                this.iniciarTimerRenovarToken()
            }
        }
    }
  }

  private renovarTokenTimeout
  private iniciarTimerRenovarToken = () => {
      const expiracao = new Date(this.usuarioLogado.expiresIn)
      const dataAtual = new Date()
      let timeout = Math.abs(expiracao.getTime() - dataAtual.getTime()) / 1000
      timeout = (timeout - this.tempoRenovacaoToken) * 1000 // A renovação iniciará antes da expiração do token
      this.renovarTokenTimeout = setTimeout(() => this.renovarToken(), timeout)
  }

  private pararTimerRenovarToken = () => {
      clearTimeout(this.renovarTokenTimeout)
  }
}
